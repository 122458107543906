@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

$max-width: 1224 * tokens.$bpk-one-pixel-rem;

.Content {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding-top: tokens.bpk-spacing-lg();
  line-height: tokens.$bpk-line-height-base-tight;
}
